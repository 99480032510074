import React, { Component } from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";

import HTML5Backend from "react-dnd-html5-backend";
import { DndProvider, DropTarget, DragSource } from "react-dnd";
import ReactMedia from "react-media";

import Layout from "../../../../components/layout";
import SEO from "../../../../components/seo";
import styles from "./activity.module.css";
import { setCurrentModule, setCurrentStep } from "../../../../state/actions";
import { func, shape, object } from "prop-types";

const Types = {
  PROCESSSTEP: "processstep"
};

const PROCESS_STEPS = [
  {
    id: "s1",
    text: "Plan ahead"
  },
  { id: "s2", text: "Case worker seeks approval from the biological parent" },
  {
    id: "s3",
    text: "The case worker will notify you of the parent's decision"
  },
  {
    id: "s4",
    text: "You can have the case worker take it to court"
  },
  {
    id: "s5",
    text: "The case worker will help you to find respite care"
  }
];

const dropTarget = {
  drop(props) {
    return { placeholderId: props.id };
  }
};

const collectTarget = (connect, monitor) => {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
};

class Placeholder extends Component {
  render() {
    const { isOver, connectDropTarget, children, className } = this.props;

    return connectDropTarget(
      <div
        className={`${styles.placeholderArea} ${className}`}
        style={{
          background: isOver ? "rgba(234, 60, 150, 0.25)" : "white"
        }}
      >
        {children}
      </div>
    );
  }
}

const dragSource = {
  beginDrag(props) {
    return { id: props.id, ...PROCESS_STEPS.find(x => x.id === props.id) };
  },

  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }
    const item = monitor.getItem();
    item.wasDropped = true;
    const dropResult = monitor.getDropResult();
    props.handleMove(item, dropResult);
  }
};

const DropPlaceholder = DropTarget(
  Types.PROCESSSTEP,
  dropTarget,
  collectTarget
)(Placeholder);

class ProcessStep extends Component {
  render() {
    const { text, isDragging, connectDragSource, className } = this.props;
    return connectDragSource(
      <div
        className={`${styles.processStep} ${className}`}
        style={{
          opacity: isDragging ? 0 : 1
        }}
      >
        <span>{text}</span>
      </div>
    );
  }
}

function collectSource(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  };
}

const DragProcessStep = DragSource(
  Types.PROCESSSTEP,
  dragSource,
  collectSource
)(ProcessStep);

class ProcessActivity extends Component {
  state = {
    hasSubmitted: false,
    hasSeenFeedback: false,
    unassignedSteps: PROCESS_STEPS,
    d1: null,
    d2: null,
    d3: null,
    d4: null,
    d5: null
  };

  static propTypes = {
    handleNavigate: func,
    goToStep: func,
    data: shape({
      file: shape({
        childImageSharp: shape({
          fixed: object
        })
      })
    })
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(2);
    this.props.goToStep(3);
  }

  goForward = () => {
    // navigate("/modules/travel/process/out-of-state-video");
  };

  goBack = () => {
    // navigate("/modules/travel/process/");
  };

  handleMove = (item, dropResult) => {
    const newUnassigned = this.state.unassignedSteps.filter(
      x => x.id !== item.id
    );
    const newDropZones = {};
    ["d1", "d2", "d3", "d4", "d5"].forEach(i => {
      newDropZones[i] =
        this.state[i] && this.state[i].id === item.id ? null : this.state[i];
    });

    if (dropResult.placeholderId === "unassigned") {
      newUnassigned.push(item);
    } else {
      newDropZones[dropResult.placeholderId] = item;
    }

    this.setState({
      unassignedSteps: newUnassigned,
      ...newDropZones
    });
  };

  renderLarge = () => {
    return (
      <DndProvider backend={HTML5Backend}>
        <DropPlaceholder
          id="unassigned"
          className={styles.unassignedDropContainer}
        >
          {this.state.unassignedSteps.map(ps => (
            <DragProcessStep
              handleMove={this.handleMove}
              key={ps.id}
              id={ps.id}
              text={ps.text}
            />
          ))}
        </DropPlaceholder>
        <div className={styles.stepContainers}>
          {["d1", "d2", "d3", "d4", "d5"].map(id => (
            <DropPlaceholder key={id} id={id}>
              {this.state[id] ? (
                <DragProcessStep
                  className={styles.onPlaceholder}
                  handleMove={this.handleMove}
                  key={this.state[id].id}
                  id={this.state[id].id}
                  text={this.state[id].text}
                />
              ) : (
                <span className={styles.largeNumber}>{id.charAt(1)}</span>
              )}
            </DropPlaceholder>
          ))}
        </div>
      </DndProvider>
    );
  };

  renderSmall = () => {
    return null;
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Travel - Process"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <ReactMedia query={{ minWidth: "768px" }}>
            {matches => (matches ? this.renderLarge() : this.renderSmall())}
          </ReactMedia>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

const ConnectedActivity = connect(
  stateToProps,
  dispatchToProps
)(ProcessActivity);

export default ConnectedActivity;
